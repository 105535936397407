import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from '@amzn/awsui-components-react';
import { ValidationContext } from 'context/ValidationContext';
import { DefinitionContext } from 'context/DefinitionContext';
import {
  checkAuthTokenExists,
  COGNITO_AUTH_URL,
  getRoles,
  getUsername,
} from 'util/services/AuthService';

import NavBar from 'views/components/common/NavBar';

import './App.css';
import Routes from 'views/components/common/Routes';
import { UserContext } from 'context/UserContext';

const App = () => {
  const location = useLocation();
  const [validations, setValidations] = useState([]);
  const updateValidations = (data) => setValidations(data);
  const [controls, setControls] = useState([]);
  const updateControls = (data) => setControls(data);
  const [definitions, setDefinitions] = useState({
    data: [],
    enabledStatus: false,
  });
  const updateDefinitions = (data) => setDefinitions(data);
  const [user, setUser] = useState({
    username: getUsername(),
    roles: getRoles(),
    preferences: {},
    notifications: {},
  });
  const updateUser = (data) => setUser(data);

  useEffect(() => {
    const checkLoggedIn = () => {
      const authTokenExists = checkAuthTokenExists();
      if (!authTokenExists && location.pathname !== '/auth') {
        window.location.replace(COGNITO_AUTH_URL);
      }
    };
    checkLoggedIn();
  });

  return (
    <div id="b">
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Spinner size="large" />
          </div>
        }
      >
        <NavBar />
        <ValidationContext.Provider
          value={{
            validations,
            controls,
            updateValidations,
            updateControls,
          }}
        >
          <UserContext.Provider
            value={{
              user,
              updateUser,
            }}
          >
            <DefinitionContext.Provider
              value={{
                definitions,
                updateDefinitions,
              }}
            >
              <Routes />
            </DefinitionContext.Provider>
          </UserContext.Provider>
        </ValidationContext.Provider>
      </Suspense>
    </div>
  );
};

export default App;
