import axios from 'axios';

import { getIdToken } from 'util/services/AuthService';

export const VALIDATION_API_URL =
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_VALIDATION_SERVICE_ENDPOINT
    : `https://api.${window.location.hostname}`;

export const AUDIT_API_URL =
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_AUDIT_SERVICE_ENDPOINT
    : `https://audit-api.${window.location.hostname}`;

export const AUDIT_ENDPOINT = `${AUDIT_API_URL}/audit`;
export const CONTROLS_ENDPOINT = `${VALIDATION_API_URL}/controls`;
export const DRAFT_ENDPOINT = `${VALIDATION_API_URL}/draft`;
export const REMEDIATION_ENDPOINT = `${VALIDATION_API_URL}/remediations`;
export const VALIDATION_ENDPOINT = `${VALIDATION_API_URL}/validation`;
export const METRICS_ENDPOINT = `${VALIDATION_API_URL}/metrics`;

export const API_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getDraft = async (id) => {
  return await get(DRAFT_ENDPOINT, id);
};

export const saveDraft = async (id, pagename, data) => {
  const payload = {
    id: `${pagename}-${id}`,
    data,
  };

  return await post(DRAFT_ENDPOINT, '', payload);
};

export const remediate = async (remediations) => {
  const payload = {
    remediations,
  };
  return await post(REMEDIATION_ENDPOINT, '', payload);
};

export const getAuditTrail = async (validationId) => {
  return await get(AUDIT_ENDPOINT, validationId);
};

export const getValidations = async (searchString) => {
  if (searchString) {
    return await get(VALIDATION_ENDPOINT, '', ['searchString=' + searchString]);
  } else return await get(VALIDATION_ENDPOINT, '');
};

export const getUserValidations = async (userId) => {
  return await get(VALIDATION_ENDPOINT, '', ['userId=' + userId]);
};

export const getValidation = async (validationId) => {
  return await get(VALIDATION_ENDPOINT, validationId);
};

export const createValidation = async (payload) => {
  return await post(VALIDATION_ENDPOINT, '', payload);
};

export const updateValidation = async (validationId, payload) => {
  return await post(VALIDATION_ENDPOINT, validationId, payload);
};

const paginateControls = async (filters) => {
  let controlsReturn = await get(CONTROLS_ENDPOINT, '', filters);

  if (!controlsReturn) {
    return controlsReturn;
  }

  let controls = controlsReturn.data.controls;
  let lastEvaluatedKey = controlsReturn.data.lastEvaluatedKey;
  while (lastEvaluatedKey) {
    let params = ['lastEvaluatedKey=' + lastEvaluatedKey];
    if (filters && filters.length > 0) {
      params.push(...filters);
    }

    let loopedReturn = await get(CONTROLS_ENDPOINT, '', params);
    if (!loopedReturn) {
      break;
    }
    let loopedControls = loopedReturn.data.controls;
    let loopedLastEvaluatedKey = loopedReturn.data.lastEvaluatedKey;
    controls.push(...loopedControls);
    lastEvaluatedKey = loopedLastEvaluatedKey;
  }
  controlsReturn.data = controls;
  return controlsReturn;
};

export const getControls = async () => {
  return paginateControls();
};

export const getEnabledControls = async () => {
  return paginateControls(['enabled=true']);
};

export const getControl = async (controlId) => {
  return await get(CONTROLS_ENDPOINT, controlId);
};

const get = async (endpoint, routeParam, filters) => {
  API_CONFIG.headers['Authorization'] = await getIdToken();

  try {
    let apiEndpoint = `${endpoint}`;

    if (routeParam) {
      apiEndpoint += `/${routeParam}`;
    }

    if (filters && filters.length > 0) {
      apiEndpoint += `/?${filters.join('&')}`;
    }

    return await emitLoadTimeMetric(
      async () => await axios.get(apiEndpoint, API_CONFIG)
    );
  } catch (err) {
    console.error(err);
  }
};

const post = async (endpoint, routeParam, payload) => {
  API_CONFIG.headers['Authorization'] = await getIdToken();
  try {
    let apiEndpoint = `${endpoint}`;

    if (routeParam) {
      apiEndpoint += `/${routeParam}`;
    }

    return await emitLoadTimeMetric(
      async () =>
        await axios.post(apiEndpoint, JSON.stringify(payload), API_CONFIG)
    );
  } catch (err) {
    throw new Error('There was an error creating the technical validation.');
  }
};

const emitLoadTimeMetric = async (apiCall) => {
  API_CONFIG.headers['Authorization'] = await getIdToken();
  try {
    const startTime = new Date().getTime();

    const response = await apiCall();

    const endTime = new Date().getTime();
    const requestTimeMillis = endTime - startTime;
    const seconds = ((requestTimeMillis % 60000) / 1000).toFixed(0);

    await axios.post(
      METRICS_ENDPOINT,
      JSON.stringify({
        metricName: 'LOAD_TIME',
        unit: 'Seconds',
        value: seconds,
      }),
      API_CONFIG
    );

    return response;
  } catch (err) {
    console.error('There was an error emitting metrics.');
  }
};

export default {
  createValidation,
  getAuditTrail,
  getControl,
  getControls,
  getDraft,
  getEnabledControls,
  getValidation,
  getValidations,
  getUserValidations,
  remediate,
  saveDraft,
};
