export const YES_NO_SELECT_OPTIONS = [
  {
    label: 'No',
    id: '0',
  },
  {
    label: 'Yes',
    id: '1',
  },
];

export const MET_NOT_MET_SELECT_OPTIONS = [
  {
    label: 'Does Not Meet Requirement',
    id: 'false',
  },
  {
    label: 'Meets Requirement',
    id: 'true',
  },
];

export const YES_NO_RADIO_OPTIONS = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
  // {
  //   value: 'NA',
  //   label: 'Not Applicable'
  // }
];

export const YES_NO_RADIO_OPTIONS_DISABLED = [
  {
    value: 'Yes',
    label: 'Yes',
    disabled: true,
  },
  {
    value: 'No',
    label: 'No',
    disabled: true,
  },
  // {
  //   value: 'NA',
  //   label: 'Not Applicable'
  // }
];
