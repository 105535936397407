import React from 'react';
import { Spinner } from '@amzn/awsui-components-react';

const Loading = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Spinner size="large" />
  </div>
);

export default Loading;
