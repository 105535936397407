import { combineReducers } from 'redux';

import metrics from 'redux/reducers/metrics';

import validations from 'redux/reducers/validations';

const rootReducer = combineReducers({
  metrics,
  validations,
});

export default rootReducer;
