import {
  FETCH_COMPETENCY_METRICS,
  FETCH_COMPETENCY_METRICS_SUCCESS,
} from 'redux/actions/metrics';

const initialState = {
  isLoading: true,
  processedDate: '...',
  metrics: {},
  segmentStackRank: [],
  pendingDocumentationMetrics: {},
};

const metrics = (state = initialState, action) => {
  if (action.type === FETCH_COMPETENCY_METRICS) {
    return {
      ...state,
      isLoading: action.isLoading,
    };
  } else if (action.type === FETCH_COMPETENCY_METRICS_SUCCESS) {
    return {
      ...state,
      isLoading: action.isLoading,
      processedDate: action.payload.processedDate,
      metrics: {
        ...state.metrics,
        ...action.payload.metrics,
      },
      segmentStackRank: [
        ...state.segmentStackRank,
        ...action.payload.segmentStackRank,
      ],
      pendingDocumentationMetrics: {
        ...state.pendingDocumentationMetrics,
        ...action.payload.pendingDocumentationMetrics,
      },
    };
  }
  return state;
};

export default metrics;
