import React from 'react';
import { FormField, Select } from '@amzn/awsui-components-react/';

const ControlledSelectField = ({
  label,
  error,
  description,
  placeholder,
  options,
  selectedOption,
  autofocus = false,
  stretch = true,
  required = false,
  onChange,
  disabled = false,
  hintText,
  enableFiltering = false,
  name,
}) => {
  return (
    <FormField
      className="awsui-util-d-b awsui-util-mb-m"
      description={description}
      errorText={error}
      stretch={stretch}
      label={label}
      hintText={hintText}
    >
      <Select
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        options={options}
        autofocus={autofocus}
        enableFiltering={enableFiltering}
        required={required}
        disabled={disabled}
        selectedOption={selectedOption}
      />
    </FormField>
  );
};

export default ControlledSelectField;
