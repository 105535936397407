import React from 'react';
import {
  Badge,
  ColumnLayout,
  ExpandableSection,
} from '@amzn/awsui-components-react/';

import ControlledTextAreaField from 'views/components/common/Form/ReactForm/ControlledTextAreaField';
import ControlledRadioGroupField from 'views/components/common/Form/ReactForm/ControlledRadioGroupField';
import { IGNORED_BY_STATUS_BADGE } from 'util/stringConstants';

import {
  YES_NO_RADIO_OPTIONS,
  YES_NO_RADIO_OPTIONS_DISABLED,
} from 'util/selectConstants';

const ChecklistRequirements = ({
  controlCategories,
  handleChange,
  validationResponse,
  stepId,
  isValidationOfMultipleCaseStudies,
  status,
  hasApplicationRequirements,
}) => {
  const validationControls = controlCategories.map(
    (category, categoryIndex) => {
      const categoryControlsComponent = category.Controls.map(
        (control, controlIndex) => {
          const controlId = control.ID;
          const VALIDATION_ATTRIBUTE = `validation`;
          const NOTES_ATTRIBUTE = `notes`;
          const ERROR_ATTRIBUTE = 'errors';

          let requirementMetValue = '';
          let requirementNotesValue = '';
          let error = '';

          if (validationResponse) {
            let conductedValidationResponse = null;
            let conductedValidationNotes = null;
            let conductedValidationErrors = null;

            if (isValidationOfMultipleCaseStudies) {
              conductedValidationResponse =
                validationResponse[stepId][VALIDATION_ATTRIBUTE];
              conductedValidationNotes =
                validationResponse[stepId][NOTES_ATTRIBUTE];
              conductedValidationErrors =
                validationResponse[stepId][ERROR_ATTRIBUTE];
            } else {
              conductedValidationResponse =
                validationResponse[VALIDATION_ATTRIBUTE];
              conductedValidationNotes = validationResponse[NOTES_ATTRIBUTE];
              conductedValidationErrors = validationResponse[ERROR_ATTRIBUTE];
            }

            if (
              conductedValidationResponse &&
              conductedValidationResponse[controlId]
            ) {
              requirementMetValue = conductedValidationResponse[controlId];
            }

            if (
              conductedValidationNotes &&
              conductedValidationNotes[controlId]
            ) {
              requirementNotesValue = conductedValidationNotes[controlId];
            }

            if (
              conductedValidationErrors &&
              conductedValidationErrors[controlId]
            ) {
              error = conductedValidationErrors[controlId];
            }
          }
          return (
            <div
              key={controlIndex}
              id={controlId}
              style={{ borderBottom: '1px solid #eaeded' }}
            >
              <ColumnLayout>
                <h4>{`${controlId} - ${control.Requirement}`}</h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: control.Description,
                  }}
                />
              </ColumnLayout>
              <ColumnLayout columns={2}>
                <div data-awsui-column-layout-root="true">
                  <ControlledRadioGroupField
                    label="Meets Requirement (Y/N)"
                    description="The solution either meets the requirement or it does not."
                    name={VALIDATION_ATTRIBUTE + controlIndex}
                    items={
                      status === 'Remediated'
                        ? YES_NO_RADIO_OPTIONS_DISABLED
                        : YES_NO_RADIO_OPTIONS
                    }
                    stretch={false}
                    value={requirementMetValue}
                    error={error}
                    onChange={(e) => {
                      handleChange(
                        controlId,
                        VALIDATION_ATTRIBUTE,
                        e.detail.value,
                        stepId
                      );
                    }}
                  />
                </div>
                {control.IgnoredByStatus === 'Yes' && (
                  <Badge color="blue">{IGNORED_BY_STATUS_BADGE}</Badge>
                )}
              </ColumnLayout>
              <ExpandableSection header="Additional Options">
                <ColumnLayout>
                  {control.SANotes && (
                    <>
                      <p>
                        <b>SA Notes</b>
                      </p>
                      <p>{control.SANotes}</p>
                    </>
                  )}
                  {control.QuestionsToAsk && (
                    <>
                      <p>
                        <b>Questions to Ask</b>
                      </p>
                      <p>{control.QuestionsToAsk}</p>
                    </>
                  )}
                </ColumnLayout>
                <ColumnLayout>
                  <div data-awsui-column-layout-root="true">
                    <ControlledTextAreaField
                      label="Please enter feedback or objective thoughts about the solution."
                      description="If an exception is made, please provide details in the text area below."
                      placeholder="Please enter here..."
                      name={NOTES_ATTRIBUTE}
                      stretch={false}
                      value={requirementNotesValue}
                      onChange={(e) => {
                        handleChange(
                          controlId,
                          NOTES_ATTRIBUTE,
                          e.detail.value,
                          stepId
                        );
                      }}
                      disabled={status === 'Remediated'}
                    />
                  </div>
                </ColumnLayout>
              </ExpandableSection>
            </div>
          );
        }
      );

      const { Category } = category;
      return (
        <React.Fragment key={categoryIndex}>
          <div>
            <h2>{Category}</h2>
          </div>
          <div className="awsui-util-container" key={categoryIndex}>
            {categoryControlsComponent}
          </div>
        </React.Fragment>
      );
    }
  );

  return <React.Fragment>{validationControls}</React.Fragment>;
};

export default ChecklistRequirements;
