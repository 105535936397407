import React from 'react';
import CheckboxSelect from 'views/components/Validation/CreateValidation/CheckboxSelect';
import ValidationType from 'views/components/Validation/CreateValidation/ValidationType';
import { Badge } from '@amzn/awsui-components-react';

export const ValidationsMap = {
  Baseline: {
    key: 'PartnerType',
    selector: CheckboxSelect,
    nameModifier: (control) => control['PartnerType'],
  },
  Competency: {
    key: 'SegmentType',
    selector: ValidationType,
    nameModifier: (control) =>
      `${control['SegmentType']} ${control['PartnerType']}`,
  },
  'Service Delivery': {
    key: 'SegmentType',
    selector: ValidationType,
    nameModifier: (control) => `${control['SegmentType']}`,
  },
  'Service Ready': {
    key: 'SegmentType',
    selector: ValidationType,
    nameModifier: (control) => `${control['SegmentType']}`,
  },
  Foundational: {
    key: 'SegmentType',
    selector: ValidationType,
    nameModifier: (control) => `${control['SegmentType']}`,
  },
  Default: {
    key: 'SegmentType',
    selector: ValidationType,
    nameModifier: (control) => `${control['SegmentType']}`,
  },
};

export const StatusMap = {
  Draft: {
    badgeModifier: () => <Badge color="grey">Draft</Badge>,
  },
  Failed: {
    badgeModifier: () => <Badge color="red">Failed</Badge>,
  },
  Passed: {
    badgeModifier: () => <Badge color="green">Passed</Badge>,
  },
  Remediated: {
    badgeModifier: () => <Badge color="blue">Remediated</Badge>,
  },
};

export const ValidationTranslation = {
  Yes: 'Passed',
  No: 'Failed',
  Remediated: 'Remediated', // Adding to show 'Remediated' status for partially remediated controls
};
