import React from 'react';
import { Table, TableSorting } from '@amzn/awsui-components-react';
import { COLUMN_DEFINITIONS, SORTABLE_COLUMNS } from './AuditTableConfig';

const Audit = (props) => {
  const { loading, logs } = props;

  return (
    <div className="awsui-grid">
      <div className="awsui-row">
        <div className="col-xxs-12 col-l-12">
          <Table
            loadingText="Loading resources"
            columnDefinitions={COLUMN_DEFINITIONS}
            items={logs}
            loading={loading}
            variant="borderless"
            header={<h2>Audit Log</h2>}
            empty={
              <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                  <b>No audit log available.</b>
                </div>
                <p className="awsui-util-mb-s">
                  Validations created before February 6th, 2020 may not have a
                  log.
                </p>
              </div>
            }
          >
            <TableSorting
              sortingColumn="date"
              sortingDescending={true}
              sortableColumns={SORTABLE_COLUMNS}
            />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Audit;
