import React, { useContext } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { UserContext } from 'context/UserContext';

import './style.css';

const NavBar = () => {
  const { user } = useContext(UserContext);

  return (
    <div className="aws-page-header" id="h">
      <header className="aws-page-main-header" role="banner">
        <div className="page-header-content">
          <div className="page-header-left">
            <Link to="/">
              <img src="/images/aws-logo-white.svg" alt="Amazon Web Services" />
            </Link>
            <NavLink className="aws-header-wise-div" to="/">
              <h1 className="aws-header-wise">Crucible</h1>
            </NavLink>
          </div>
          <div className="page-header-right">{user.username}</div>
        </div>
      </header>
    </div>
  );
};

export default withRouter(NavBar);
