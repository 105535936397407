export const ValidationModel = (data) => {
  if (data.hasMultipleCaseStudies) {
    return {
      id: data.validationReferenceId,
      sfdcId: data.sfdcId,
      validationReferenceId: data.validationReferenceId,
      validationId: data.validationId,
      partner: data.partner,
      workload: data.workload,
      evaluationDate: data.evaluationDate,
      validationType: data.validationType,
      categories: data.categories,
      validations: data.validations ? data.validations : {},
      status: data.status,
      userId: data.userId,
      hasMultipleCaseStudies: data.hasMultipleCaseStudies,
      hasApplicationRequirements: data.hasApplicationRequirements,
      reviewMechanismUsed: data.reviewMechanismUsed
        ? data.reviewMechanismUsed
        : '',
      timeOnReview: data.timeOnReview,
    };
  } else {
    return {
      id: data.validationReferenceId,
      sfdcId: data.sfdcId,
      validationReferenceId: data.validationReferenceId,
      validationId: data.validationId,
      partner: data.partner,
      workload: data.workload,
      evaluationDate: data.evaluationDate,
      validationType: data.validationType,
      categories: data.categories,
      validation: data.validation ? data.validation : {},
      notes: data.notes,
      remediations: data.remediations,
      status: data.status,
      userId: data.userId,
      hasMultipleCaseStudies: data.hasMultipleCaseStudies,
      hasApplicationRequirements: data.hasApplicationRequirements,
      reviewMechanismUsed: data.reviewMechanismUsed
        ? data.reviewMechanismUsed
        : '',
      timeOnReview: data.timeOnReview,
    };
  }
};
