import React from 'react';
import {
  Checkbox,
  ColumnLayout,
  FormField,
  FormSection,
} from '@amzn/awsui-components-react';
import { Button } from '@amzn/awsui-components-react/';
import ControlledInputField from 'views/components/common/Form/ReactForm/ControlledInputField';
import ControlledSelectField from 'views/components/common/Form/ReactForm/ControlledSelectField';
import { ReviewMechanismType } from 'util/constants/validation-type-constants';
import './style.css';
import { createSelectionMap } from 'util/core';

const ValidationType = ({
  sfdcId,
  selectedCategoryIds,
  customerReferences,
  error,
  validationType,
  categories,
  handleCheckboxSelect,
  handleDropdownSelect,
  handleInputChange,
  handleAddCaseClick,
  handleRemoveCaseClick,
  handleCaseInputChange,
  reviewMechanismUsed,
  disabled = false,
}) => {
  // Set control labels & descriptions
  let validationIdLabel = '',
    validationIdDescription = '';

  const reviewMechanismsForSelect = createSelectionMap(ReviewMechanismType);

  switch (validationType) {
    case 'Competency':
      validationIdLabel = 'Technical Validation ID';
      validationIdDescription =
        'Technical Validation ID: Baseline use SFDC ID (eg.a3I0h000000boEb); Everything else SIM ID (eg. PSA-DEFAULT-1234)';
      break;
    case 'Foundational':
      validationIdLabel = 'Validation Record';
      validationIdDescription = 'SFDC ID for the FTR (ex: a3I0h000000boEb)';
      break;
    case 'Service Delivery':
      validationIdLabel = 'Validation Record';
      validationIdDescription =
        'SFDC ID for the SDP Application (ex: a418a00000ISgsM)';
      break;
    case 'Service Ready':
      validationIdLabel = 'Validation Record';
      validationIdDescription =
        'SFDC ID for the SRP Application (ex: a418a00000ISgsM)';
      break;
    case 'Solution Space':
      validationIdLabel = 'Technical Validation ID';
      validationIdDescription =
        'Technical Validation ID: Baseline use SFDC ID (eg.a3I0h000000boEb); Everything else SIM ID (eg. PSA-DEFAULT-1234)';
      break;
    default:
      validationIdLabel = 'Technical Validation ID';
      validationIdDescription =
        'Technical Validation ID: Baseline use SFDC ID (eg.a3I0h000000boEb); Everything else SIM ID (eg. PSA-DEFAULT-1234)';
      break;
  }

  return (
    <div>
      <FormSection header={`${validationType}`}>
        <ColumnLayout borders="horizontal" columns={2}>
          <div data-awsui-column-layout-root="true">
            {validationType === 'Competency' ||
            validationType === 'Service Delivery' ||
            validationType === 'Service Ready' ? (
              <ControlledSelectField
                label={`${validationType} Type`}
                description={`Please select a ${validationType} type.`}
                name="componentType"
                error={error && error['dropdownSelect']}
                options={categories.sort((category1, category2) => {
                  return category1.label.localeCompare(category2.label, 'en', {
                    sensitivity: 'base',
                  });
                })}
                onChange={handleDropdownSelect}
                enableFiltering={true}
                disabled={disabled}
                selectedOption={
                  selectedCategoryIds && selectedCategoryIds.length
                    ? categories.find(
                        (category) => category.id === selectedCategoryIds[0]
                      )
                    : undefined
                }
              />
            ) : (
              <FormField errorText={error && error['checkboxSelect']}>
                {categories
                  .sort((a, b) => a.id > b.id)
                  .map((category, index) => {
                    return (
                      <Checkbox
                        key={index}
                        label={category.label}
                        name={category.id}
                        onChange={(e) => {
                          handleCheckboxSelect(e, category.id);
                        }}
                        disabled={disabled}
                        {...(selectedCategoryIds && selectedCategoryIds.length
                          ? {
                              checked: selectedCategoryIds.includes(
                                category.id
                              ),
                            }
                          : {})}
                      />
                    );
                  })}
              </FormField>
            )}
            <div>
              <ControlledInputField
                label={validationIdLabel}
                description={validationIdDescription}
                value={sfdcId}
                name="sfdcId"
                placeholder="a3I0h000000boEb or PSA-DEFAULT-1234"
                error={error && error['sfdcId']}
                onChange={({ detail: { value } }) =>
                  handleInputChange('sfdcId', value)
                }
              />
              {validationType === 'Foundational' && (
                <ControlledSelectField
                  label="Review Mechanism"
                  description="Please select the review mechanism used."
                  name="reviewMechanismUsed"
                  options={reviewMechanismsForSelect}
                  error={error && error['reviewMechanismUsed']}
                  onChange={(e) =>
                    handleInputChange(
                      'reviewMechanismUsed',
                      e.detail.selectedOption.id
                    )
                  }
                  disabled={disabled}
                  selectedOption={
                    reviewMechanismUsed
                      ? reviewMechanismsForSelect.find(
                          (mechanism) => mechanism.id === reviewMechanismUsed
                        )
                      : undefined
                  }
                />
              )}
            </div>
          </div>
        </ColumnLayout>
      </FormSection>

      {customerReferences && customerReferences.length > 0 && (
        <div errortext={error && error['customerReferences']}>
          <h2>Customer References</h2>
          <FormField errorText={error && error['customerReferences']} />
          {customerReferences.map((x, i) => {
            return (
              <div className="awsui-util-container" key={i}>
                <div className="awsui-util-container-header">
                  <div className="awsui-util-action-stripe">
                    <div className="awsui-util-action-stripe-title">
                      <h2>{`Customer Reference ${i + 1}`}</h2>
                    </div>
                    <div className="awsui-util-action-stripe-group">
                      <Button
                        onClick={() => handleRemoveCaseClick(i)}
                        variant="primary"
                      >
                        Remove
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <ColumnLayout borders="horizontal" columns={2}>
                    <div data-awsui-column-layout-root="true">
                      <ControlledInputField
                        label={`Customer Name`}
                        description="Please enter the name of the customer referenced in the Case Study."
                        value={x.customerName}
                        name={`customerName${i + 1}`}
                        placeholder="Enter the customer name..."
                        error={error && error[`customerName${i + 1}`]}
                        onChange={({ detail: { value } }) =>
                          handleCaseInputChange('customerName', value, i)
                        }
                      />
                      <ControlledInputField
                        label={`Asset ID`}
                        description="Please enter the Case Study ID from SFDC (e.g. a2o8a000005rMUA)."
                        value={x.assetId}
                        name={`assetId${i + 1}`}
                        placeholder="Enter the assetId ..."
                        error={error && error[`assetId${i + 1}`]}
                        onChange={({ detail: { value } }) =>
                          handleCaseInputChange('assetId', value, i)
                        }
                      />
                    </div>
                  </ColumnLayout>
                </div>
              </div>
            );
          })}
          <div>
            <Button onClick={handleAddCaseClick}>Add Customer Reference</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ValidationType;
