import React, { useContext } from 'react';
import { Flash } from '@amzn/awsui-components-react';
import { acknowledgeNotification, UserContext } from 'context/UserContext';

import { ACTIVE } from 'util/stringConstants';

const Notifications = () => {
  const { user, updateUser } = useContext(UserContext);

  const notifications = () => {
    const notificationsCollection = user.notifications;
    const filteredNotifications = [];

    Object.keys(notificationsCollection)
      .filter((key) => {
        return notificationsCollection[key]['status'] === ACTIVE;
      })
      .map((key) => {
        const notification = notificationsCollection[key];
        notification['id'] = key;
        filteredNotifications.push(notification);
        return notification;
      });
    return filteredNotifications;
  };

  const handleAcknowledgeClick = (e) => {
    const id = e.target.getAttribute('data-id');
    updateUser(acknowledgeNotification(user, id));
  };

  return (
    <div id="notifications">
      {notifications &&
        notifications.length > 0 &&
        notifications.map((notification, index) => {
          return (
            <Flash
              id={`notification-${index}`}
              key={notification.id}
              type={notification.type}
              header={notification.header}
              dismissible={notification.dismissible}
              onDismiss={(e) => handleAcknowledgeClick(e)}
              loading={notification.loading ? true : false}
              data-id={notification.id}
            >
              {notification.content}
            </Flash>
          );
        })}
    </div>
  );
};

export default Notifications;
