import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import ReduxThunk from 'redux-thunk';
import rootEpic from 'redux/epic/rootEpic';
import rootReducer from 'redux/reducers/rootReducer';

const epicMiddleware = createEpicMiddleware();

const appStore = () => {
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose;
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(epicMiddleware, ReduxThunk))
  );

  epicMiddleware.run(rootEpic);
  return store;
};

export default appStore;
