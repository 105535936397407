import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  ButtonDropdown,
  ColumnLayout,
} from '@amzn/awsui-components-react';

import Loading from 'views/components/common/Loading';
import SummaryComponent from './SummaryComponent';
import API from 'util/services/API';

import { StatusMap } from 'config/validations';
import { getValidationControlMapping, isRemediated } from 'util/core';

import './style.css';

const Summary = (props) => {
  const validationId = props.match.params.id;
  const [loading, setLoading] = useState(true);
  const [validation, setValidation] = useState({});
  const [controls, setControls] = useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (validationId && validationId !== '') {
          const validationResults = await API.getValidation(validationId);

          const { categories } = validationResults.data;
          const controlResults = [];
          for (const category of categories) {
            const controlResult = await API.getControl(category);
            controlResults.push(controlResult.data.Item);
          }

          setValidation(validationResults.data);
          setControls(controlResults);
          setLoading(false);
        } else {
          throw Error('Validation Id is not valid.');
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [validationId]);

  let {
    categories,
    evaluationDate,
    partner,
    sfdcId,
    validationReferenceId,
    status,
    validationType,
    workload,
    userId,
    validations,
  } = validation;

  const items = [
    {
      id: 'editValidation',
      disabled: isRemediated(validation),
      text: 'Edit validation',
      description: 'Edit the validation',
    },
    {
      id: 'editAttributes',
      text: 'Edit validation attributes',
      description: `Edit the validation's attributes`,
    },
  ];

  const validationCollection = {};
  const ValidationTypesMapping = getValidationControlMapping(controls);

  let Summary = <Loading />;

  function getValidationControls(validation) {
    let validationControls = [];
    controls.filter((definition) => {
      definition.ControlCategories.filter((controlCategory) => {
        controlCategory.Controls.filter((control) => {
          return validation.hasApplicationRequirements
            ? control.Scope === 'Application'
            : control.Scope !== 'Application';
        }).map((control) => {
          let controlCategoryItem = validationControls.find(
            (item) => item.Category === controlCategory.Category
          );
          if (controlCategoryItem) {
            controlCategoryItem.Controls.push(control);
          } else {
            validationControls.push({
              Category: controlCategory.Category,
              Description: controlCategory.Description,
              Controls: [control],
            });
          }
          return control;
        });
        return controlCategory;
      });
      return definition;
    });

    return validationControls;
  }

  if (!loading) {
    let validationControls = [];
    if (!validation.hasMultipleCaseStudies) {
      validationControls = getValidationControls(validation);
      validationCollection[validation.validationId] = {
        validationId: validation.validationId,
        validationReferenceId: validation.validationReferenceId,
        categories,
        evaluationDate,
        notes: validation.notes,
        remediations: validation.remediations,
        validation: validation.validation,
        partner,
        sfdcId,
        status,
        validationType,
        workload,
        userId,
        assetId: null,
        customerName: null,
        hasMultipleCaseStudies: false,
        hasApplicationRequirements: validation.hasApplicationRequirements,
        validationControls: validationControls,
      };
    } else {
      Object.keys(validations).forEach((key) => {
        validationControls = getValidationControls(validations[key]);

        validationCollection[key] = {
          validationId: key,
          validationReferenceId: validation.validationReferenceId,
          categories,
          evaluationDate,
          notes: validations[key].notes,
          remediations: validations[key].remediations,
          validation: validations[key].validation,
          partner,
          sfdcId,
          status,
          validationType,
          workload,
          userId,
          assetId: validations[key].assetId,
          customerName: validations[key].customerName,
          hasMultipleCaseStudies: true,
          hasApplicationRequirements:
            validations[key].hasApplicationRequirements,
          validationControls: validationControls,
        };
      });
    }

    Summary = (
      <React.Fragment>
        <div className="awsui-grid">
          <div className="awsui-row">
            <div className="col-xxs-12 col-l-12">
              <div className="awsui-util-container">
                <div className="awsui-util-action-stripe-large">
                  <div className="awsui-util-action-stripe-title">
                    <h2 className="awsui-util-font-size-3">{partner}</h2>
                    {workload ? (
                      <h3 className="awsui-util-font-size-3">
                        Workload - {workload}
                      </h3>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="awsui-util-action-stripe-group">
                    <Button
                      text="Remediate"
                      variant="primary"
                      disabled={status !== 'Failed'}
                      onClick={() => {
                        props.history.push(
                          `/validations/${validationReferenceId}/remediations`
                        );
                      }}
                    />
                    <ButtonDropdown
                      items={items}
                      onItemClick={(e) => {
                        const id = e.detail.id;
                        if (id === 'editValidation') {
                          props.history.push(
                            `/validations/${validationReferenceId}`
                          );
                        } else if (id === 'editAttributes') {
                          props.history.push(
                            `/validations/${validationReferenceId}/attributes`,
                            validation
                          );
                        }
                      }}
                    >
                      Edit
                    </ButtonDropdown>
                    <Button
                      text="View Report"
                      variant="primary"
                      onClick={() => {
                        props.history.push(
                          `/validations/${validationReferenceId}/report`
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="awsui-row">
            <div className="col-xxs-12 col-l-12">
              <div className="awsui-util-container">
                <ColumnLayout columns={3} borders="vertical">
                  <div data-awsui-column-layout-root="true">
                    <div>
                      <div className="awsui-util-spacing-v-s">
                        <div>
                          <div className="awsui-util-label">Id</div>
                          <div>
                            <a
                              href={`https://apn-portal.my.salesforce.com/${sfdcId}`}
                            >
                              {sfdcId}
                            </a>
                          </div>
                        </div>
                        <div>
                          <div className="awsui-util-label">Partner</div>
                          <div>{partner}</div>
                        </div>
                        {workload ? (
                          <div>
                            <div className="awsui-util-label">Workload</div>
                            <div>{workload}</div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="awsui-util-spacing-v-s">
                        <div>
                          <div className="awsui-util-label">
                            Validation Type
                          </div>
                          <div>{validationType}</div>
                        </div>
                        <div>
                          <div className="awsui-util-label">Categories</div>
                          <div className="awsui-grid">
                            <div className="awsui-row">
                              {categories &&
                                categories.length > 0 &&
                                categories.map((category, index) => (
                                  <div
                                    className="col-xxs-12 col-l-12"
                                    key={index}
                                  >
                                    {ValidationTypesMapping[category]}
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="awsui-util-spacing-v-s">
                        <div>
                          <div className="awsui-util-label">
                            Evaluation Date
                          </div>
                          <div>{evaluationDate}</div>
                        </div>
                        <div>
                          <div className="awsui-util-label">
                            Last Updated By
                          </div>
                          <div>
                            <a
                              href={`https://phonetool.amazon.com/users/${userId}`}
                            >
                              {`${userId}@`}
                            </a>
                          </div>
                        </div>
                        <div>
                          <div className="awsui-util-label">Status</div>
                          <div>
                            {status && StatusMap[status].badgeModifier()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ColumnLayout>
              </div>
            </div>
          </div>
          <div className="awsui-grid">
            <div className="awsui-row">
              <div className="col-xxs-12 col-l-12">
                <SummaryComponent
                  validations={validationCollection}
                  hasMultipleCaseStudies={validation.hasMultipleCaseStudies}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  return Summary;
};

export default withRouter(Summary);
