import { createContext } from 'react';
import { getRoles, getUsername } from 'util/services/AuthService';
import { ACTIVE, DISMISSED, ERROR } from 'util/stringConstants';

export const UserContext = createContext({
  user: {
    username: getUsername(),
    roles: getRoles(),
    preferences: {},
    notifications: {},
  },
  updateUser: () => {},
});

const uuid = require('uuid/v4');

const addNotificationInfoToUser = (user, notificationId, notificationInfo) => {
  return {
    ...user,
    notifications: {
      ...user.notifications,
      [notificationId]: {
        ...user.notifications[notificationId],
        ...notificationInfo,
      },
    },
  };
};

export const errorNotification = (user, content) => {
  const notificationId = uuid();
  const notificationInfo = {
    content,
    date: new Date(),
    type: ERROR,
    loading: false,
    header: 'Notification',
    dismissible: true,
    status: ACTIVE,
  };
  const updatedUser = addNotificationInfoToUser(
    user,
    notificationId,
    notificationInfo
  );
  return updatedUser;
};

export const acknowledgeNotification = (user, notificationId) => {
  const notificationInfo = {
    status: DISMISSED,
  };
  const updatedUser = addNotificationInfoToUser(
    user,
    notificationId,
    notificationInfo
  );
  return updatedUser;
};
