import { createSelector } from 'reselect';
import {
  CONDUCT_VALIDATION_SUCCESS,
  CREATE_VALIDATION_SUCCESS,
  FETCH_VALIDATIONS_SUCCESS,
  VALIDATION_LOADING,
} from 'redux/actions/validations';
import TechnicalValidationRequirements from 'views/components/Validation/TechnicalValidationRequirements';

const defaultState = {
  isLoading: false,
  validation: {
    // a12345: {
    //   partner: 'Deloitte',
    //   workload: 'Website',
    //   requestDate: '',
    //   assignedDate: '',
    //   evaluationDate: '',
    //   validationType: '',
    //   baselineType: {},
    //   competencyType: {},
    //   validation: {},
    //   status: '',
    // },
    // abc: {
    //   partner: 'Amazon',
    //   workload: 'App',
    //   requestDate: '',
    //   assignedDate: '',
    //   evaluationDate: '',
    //   validationType: '',
    //   baselineType: {},
    //   competencyType: {},
    //   validation: {},
    //   status: '',
    // },
    // abc12: {
    //   partner: 'AWS',
    //   workload: 'Lambda',
    //   requestDate: '',
    //   assignedDate: '',
    //   evaluationDate: '',
    //   validationType: '',
    //   baselineType: {},
    //   competencyType: {},
    //   validation: {},
    //   status: '',
    // },
    // cba21: {
    //   partner: 'AWS',
    //   workload: 'APGW',
    //   requestDate: '',
    //   assignedDate: '',
    //   evaluationDate: '',
    //   validationType: '',
    //   baselineType: {},
    //   competencyType: {},
    //   validation: {},
    //   status: '',
    // },
  },
};

export const validations = (state = defaultState, action) => {
  if (action.type === VALIDATION_LOADING) {
    return {
      ...state,
      isLoading: action.isLoading,
    };
  } else if (action.type === CREATE_VALIDATION_SUCCESS) {
    return {
      ...state,
      isLoading: action.isLoading,
      validation: {
        ...state.validation,
        [action.payload.id]: { ...action.payload.details },
      },
    };
  } else if (action.type === CONDUCT_VALIDATION_SUCCESS) {
    return {
      ...state,
      isLoading: action.isLoading,
      validation: {
        ...state.validation,
        [action.payload.id]: { ...action.payload.details },
      },
    };
  } else if (action.type === FETCH_VALIDATIONS_SUCCESS) {
    return {
      ...state,
      isLoading: action.isLoading,
      validation: {
        ...action.payload,
      },
    };
  }
  return state;
};

export default validations;

export const getIsValidationLoading = (state) => state.validations.isLoading;
// export const getAllValidations = (state) => state.validations.validation;
export const getValidationDetailByValidationId = (state, props) => {
  const validation = state.validations.validation[props.match.params.id];

  let validationDetails = {};
  if (validation) {
    if (validation.hasMultipleCaseStudies) {
      validationDetails = {
        validationId: validation.validationId,
        sfdcId: validation.sfdcId,
        validationReferenceId: props.match.params.id,
        partner: validation.partner,
        workload: validation.workload,
        evaluationDate: validation.evaluationDate,
        validationType: validation.validationType,
        categories: validation.categories,
        status: validation.status,
        userId: validation.userId,
        validations: validation.validations,
        hasMultipleCaseStudies: validation.hasMultipleCaseStudies,
        hasApplicationRequirements: validation.hasApplicationRequirements,
        reviewMechanismUsed: validation.reviewMechanismUsed,
        timeOnReview: validation.timeOnReview,
      };
    } else {
      validationDetails = {
        sfdcId: validation.sfdcId,
        validationId: validation.validationId,
        validationReferenceId: props.match.params.id,
        partner: validation.partner,
        workload: validation.workload,
        evaluationDate: validation.evaluationDate,
        validationType: validation.validationType,
        categories: validation.categories,
        status: validation.status,
        userId: validation.userId,
        validation: validation.validation,
        notes: validation.notes,
        remediations: validation.remediations,
        hasMultipleCaseStudies: validation.hasMultipleCaseStudies,
        hasApplicationRequirements: validation.hasApplicationRequirements,
        reviewMechanismUsed: validation.reviewMechanismUsed,
        timeOnReview: validation.timeOnReview,
      };
    }
  }

  return validationDetails;
};

export const getValidationByValidationId = (state, props) => {
  const validations = state.validations.validation[props.match.params.id];
  // Return the validation record
  return validations ? validations : {};
};

export const getFailedValidationControls = createSelector(
  [getValidationByValidationId],
  (validations) => {
    // Get the list of all validations
    const validation =
      validations && validations.validation ? validations.validation : {};

    const failedControls = [];
    const controls = Object.keys(validation);
    if (controls.length > 0) {
      controls.map((control) => {
        // Check whether the validation is 'No'
        if (validation[control] === 'No') {
          // Check whether the validation is partially remediated
          if (
            !validations.remediations ||
            (validations.remediations &&
              validations.remediations[control] &&
              validations.remediations[control].validation === 'No')
          ) {
            failedControls.push(control);
          }
        }
        return control;
      });
    }
    return failedControls;
  }
);

export const getPartnerDetail = createSelector(
  [getValidationDetailByValidationId],
  (partner) => partner
);

export const stepInfoSelector = createSelector(
  [getValidationDetailByValidationId],
  (partnerDetails) => {
    const { categories } = partnerDetails;
    const wizardSteps = [];
    if (partnerDetails) {
      categories.forEach((category) => {
        const validation = {
          id: category,
          title: `${category}`,
          component: TechnicalValidationRequirements,
        };
        wizardSteps.push(validation);
      });
    }
    return wizardSteps;
  }
);
