/**
 * https://www.w3.org/International/O-charset-lang.html
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 * https://www.loc.gov/standards/iso639-2/englangn.html
 */
const languageCodes = {
  en: 'en',
};

Object.freeze(languageCodes);

const codeToLanguageMap = {
  en: 'English',
  cn: 'Chinese (中文)',
  ja: 'Japanese (日本語)',
  pt: 'Portuguese (Português)',
  es: 'Spanish (Español)',
};

export { languageCodes, codeToLanguageMap };
