import React, { useState } from 'react';
import API from 'util/services/API';
import Audit from 'views/components/Validation/Summary/Audit';

const AuditContainer = (props) => {
  const { validationId, sfdcId } = props;
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        let auditLog = [];

        // First append the legacy history by sfdcId
        try {
          console.log('Get audit log by sfdcId');
          let auditLogBysfdcId = await API.getAuditTrail(sfdcId);

          if (auditLogBysfdcId) {
            auditLog = [...auditLogBysfdcId.data.data];
          }
        } catch (error) {
          console.error(error);
        }

        // Then Filter out any history pertaining to CREATED events as we this will be created due to data migration import and add the rest.
        try {
          console.log('Get audit log by validationId');
          let auditLogByvalidationId = await API.getAuditTrail(validationId);

          if (auditLogByvalidationId) {
            // If auditLog already exists then filter out the CREATED events
            if (auditLog.length > 0) {
              const results = auditLogByvalidationId.data.data.filter((obj) => {
                return obj.event_type !== 'CREATED';
              });
              // Append the history
              auditLog = [...auditLog, ...results];
            } else {
              // Append the history
              auditLog = [...auditLog, ...auditLogByvalidationId.data.data];
            }
          }
        } catch (error) {
          console.error(error);
        }

        setLogs(auditLog);
        setLoading(false);
      } catch (err) {
        console.log('Error occured while getting audit history');
        console.error(err);
        setLogs([]);
        setLoading(false);
      }
    };
    if (!logs.length > 0) {
      fetchData();
    }
  }, [logs.length, setLoading, validationId, sfdcId]);

  return <Audit loading={loading} logs={logs} />;
};

export default AuditContainer;
