import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from '@amzn/awsui-components-react/';

import { redirectHome } from 'util/core';

const WizardFooter = ({
  activeStep,
  handleSubmit,
  history,
  isLoading,
  nextStep,
  numberOfSteps,
  previousStep,
  status,
}) => {
  const backButton =
    activeStep !== 0 ? (
      <Button className="prev-step-btn" onClick={previousStep}>
        Previous
      </Button>
    ) : null;

  const nextButton =
    activeStep !== numberOfSteps ? (
      <Button
        className="next-step-btn"
        variant="primary"
        onClick={nextStep}
        disabled={status === 'Remediated'}
      >
        Next
      </Button>
    ) : (
      <Button
        loading={isLoading}
        onClick={handleSubmit}
        variant="primary"
        disabled={status === 'Remediated'}
      >
        Submit
      </Button>
    );

  return (
    <div>
      <Button variant="link" onClick={() => redirectHome(history)}>
        Cancel
      </Button>
      {backButton}
      {/* <Button onClick={() => {}} text='Save as Draft' variant='primary' /> */}
      {nextButton}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  //TODO: Remove/change this
  return {
    dispatch,
  };
};

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(WizardFooter);

export default withRouter(connectDecoratedComponent);
