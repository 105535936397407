const ValidationType = {
  Baseline: 'Baseline',
  ServiceDelivery: 'ServiceDelivery',
  Competency: 'Competency',
  Default: 'Default',
};

/** Foundational Technical Review Mechanism Type */
const ReviewMechanismType = ['Online', 'Offline', 'Email'];

export { ValidationType, ReviewMechanismType };
