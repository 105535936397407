import React from 'react';
import { AppLayout } from '@amzn/awsui-components-react';

import Notifications from 'views/components/common/Notifications';
import NavigationDrawer from 'views/components/common/NavigationDrawer';

const ConsoleWrapper = ({ component }) => {
  const appLayoutLabels = {
    navigationToggle: 'Open navigation drawer',
    navigationClose: 'Close navigation drawer',
    toolsToggle: 'Open tools drawer',
    toolsClose: 'Close navigation drawer',
  };
  return (
    <div>
      <AppLayout
        content={component}
        navigation={<NavigationDrawer />}
        notifications={<Notifications />}
        toolsHide={true}
        labels={appLayoutLabels}
      />
    </div>
  );
};
export default ConsoleWrapper;
