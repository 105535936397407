import { lazy } from 'react';
import Summary from 'views/components/Validation/Summary';

const PSADashboard = lazy(() => import('views/components/Dashboard/PSA'));

const TechnicalValidationTable = lazy(() =>
  import('views/components/Validation/Table/Container')
);
const CycleTime = lazy(() => import('views/components/Competency/CycleTime'));
const Segment = lazy(() => import('views/components/Competency/Segment'));
const CreateValidation = lazy(() =>
  import('views/components/Validation/CreateValidation')
);
const EditValidation = lazy(() =>
  import('views/components/Validation/EditValidation')
);
const TechnicalValidationWizard = lazy(() =>
  import('views/components/Validation/Wizard')
);
const ValidationReport = lazy(() =>
  import('views/components/Validation/Report')
);
const Metrics = lazy(() => import('views/components/Validation/Metrics'));
const Definitions = lazy(() =>
  import('views/components/Validation/Definitions')
);
const Remediations = lazy(() =>
  import('views/components/Validation/Remediations')
);

export const CRUCIBLE_BETA_ROLE = 'crucible-beta';
export const CRUCIBLE_PROGRAMS_BETA_ROLE = 'crucible-programs-beta';
export const CRUCIBLE_ADMIN_ROLE = 'crucible-admin';

export const RouteType = {
  PARENT: 'parent',
  CHILD: 'child',
};

// Order matters here. The first item that shows up will be the default page that gets loaded for users.
const possibleRoutes = [
  {
    name: 'Dashboard',
    permitted: [CRUCIBLE_ADMIN_ROLE, CRUCIBLE_BETA_ROLE],
    type: RouteType.PARENT,
    path: '/dashboard',
    main: true,
    component: PSADashboard,
  },
  {
    name: 'Metrics',
    parent: 'Validations',
    type: RouteType.CHILD,
    path: '/validations/metrics',
    permitted: [CRUCIBLE_ADMIN_ROLE],
    component: Metrics,
  },
  {
    name: 'Definitions',
    parent: 'Validations',
    type: RouteType.CHILD,
    path: '/validations/definitions',
    permitted: [CRUCIBLE_BETA_ROLE],
    component: Definitions,
  },
  {
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_BETA_ROLE],
    path: '/validations/definitions/:validationType',
    component: Definitions,
  },
  {
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_BETA_ROLE],
    path: '/validations/new',
    component: CreateValidation,
  },
  {
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_BETA_ROLE],
    path: '/validations/:id/remediations',
    component: Remediations,
  },
  {
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_BETA_ROLE],
    path: '/validations/:id/summary',
    component: Summary,
  },
  {
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_BETA_ROLE],
    path: '/validations/:id/report',
    component: ValidationReport,
  },
  {
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_BETA_ROLE],
    path: '/validations/:id/attributes',
    component: EditValidation,
  },
  {
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_BETA_ROLE],
    path: '/validations/:id',
    component: TechnicalValidationWizard,
  },
  {
    name: 'Validations',
    permitted: [CRUCIBLE_BETA_ROLE],
    type: RouteType.PARENT,
    path: '/validations',
    main: true,
    component: TechnicalValidationTable,
  },
  {
    name: 'Segment',
    parent: 'Competency',
    type: RouteType.CHILD,
    permitted: [CRUCIBLE_PROGRAMS_BETA_ROLE],
    path: '/competency/segment',
    component: Segment,
  },
  {
    name: 'Competency',
    type: RouteType.PARENT,
    permitted: [CRUCIBLE_PROGRAMS_BETA_ROLE],
    path: '/competency',
    main: true,
    component: CycleTime,
  },
];

export const routeFactory = (roles) => {
  let routes = [];
  const addedRoutes = [];
  if (roles.includes(CRUCIBLE_ADMIN_ROLE)) {
    routes = possibleRoutes;
  } else {
    for (let role of roles) {
      routes = routes.concat(
        routes,
        possibleRoutes.filter((route) => {
          const permitted =
            !addedRoutes.includes(route.path) && route.permitted.includes(role);
          if (permitted) {
            addedRoutes.push(route.path);
          }
          return permitted;
        })
      );
    }
  }
  return routes;
};

// export default roles;
