import React from 'react';
import { StatusMap, ValidationTranslation } from 'config/validations';
import { Badge, ColumnLayout } from '@amzn/awsui-components-react';
import { SpaceBetween } from '@amzn/awsui-components-react-v3';
import AuditContainer from 'views/components/Validation/Summary/Audit/AuditContainer';
import { IGNORED_BY_STATUS_BADGE } from 'util/stringConstants';

const ValidationComponent = ({ validation }) => {
  let { validationId, sfdcId, notes, remediations } = validation;

  const requirements = validation.validation;

  const validationComponents = validation.validationControls.map(
    (controlCategory) => {
      return controlCategory.Controls.map((categoryControl, index) => {
        const id = categoryControl.ID;
        const requirement = categoryControl.Requirement;
        const ignoredByStatus = categoryControl.IgnoredByStatus;

        return (
          <div className="awsui-util-container" key={index}>
            <div className="awsui-util-container-header">
              <div className="summary-control-header">
                <h2>{id}</h2>
                <div>
                  <SpaceBetween direction="horizontal" size="xs">
                    {ignoredByStatus === 'Yes' && (
                      <Badge color="blue">{IGNORED_BY_STATUS_BADGE}</Badge>
                    )}

                    {requirements[id] &&
                      requirements[id] === 'Yes' &&
                      StatusMap[
                        ValidationTranslation[requirements[id]]
                      ].badgeModifier()}

                    {requirements[id] &&
                      requirements[id] === 'No' &&
                      !remediations &&
                      StatusMap[
                        ValidationTranslation[requirements[id]]
                      ].badgeModifier()}

                    {requirements[id] &&
                      requirements[id] === 'No' &&
                      remediations &&
                      remediations[id] &&
                      remediations[id].validation === 'No' &&
                      StatusMap[
                        ValidationTranslation[remediations[id].validation]
                      ].badgeModifier()}

                    {requirements[id] &&
                      requirements[id] === 'No' &&
                      remediations &&
                      remediations[id] &&
                      remediations[id].validation === 'Yes' &&
                      StatusMap[
                        ValidationTranslation['Remediated']
                      ].badgeModifier()}
                  </SpaceBetween>
                </div>
              </div>
              <div className="awsui-util-container-header-description">
                {requirement}
              </div>
            </div>

            <div className="col-xxs-6 col-l-6">
              <h4>Evaluator Notes:</h4>
              <p>{notes && notes[id]}</p>
            </div>

            {remediations && remediations[id] && (
              <div className="col-xxs-12 col-l-12">
                <ColumnLayout columns={3} borders="none">
                  <div data-awsui-column-layout-root="true">
                    <div>
                      <div className="awsui-util-spacing-v-s">
                        <div>
                          <div className="awsui-util-label">
                            <strong>Remediation Notes</strong>
                          </div>
                          <div>
                            {remediations[id].hasOwnProperty('note')
                              ? remediations[id].note
                              : remediations[id]}
                          </div>
                        </div>
                      </div>
                    </div>
                    {remediations && remediations[id] && (
                      <div>
                        <div className="awsui-util-spacing-v-s">
                          <div>
                            <div className="awsui-util-label">
                              <strong>Remediation Date</strong>
                            </div>
                            <div>{remediations[id].date}</div>
                          </div>
                        </div>
                      </div>
                    )}
                    {remediations && remediations[id] && (
                      <div>
                        <div className="awsui-util-spacing-v-s">
                          <div>
                            <div className="awsui-util-label">
                              <strong>
                                Does the solution meet the requirement?
                              </strong>
                            </div>
                            <div>{remediations[id].validation}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </ColumnLayout>
              </div>
            )}
          </div>
        );
      });
    }
  );

  return (
    <React.Fragment>
      {validationComponents}
      <AuditContainer validationId={validationId} sfdcId={sfdcId} />
    </React.Fragment>
  );
};

export default ValidationComponent;
