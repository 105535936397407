import axios from 'axios';
import { getIdTokenFromStorage } from 'util/services/AuthService';

export const FETCH_COMPETENCY_METRICS = 'FETCH_COMPETENCY_METRICS';
export const FETCH_COMPETENCY_METRICS_SUCCESS =
  'FETCH_COMPETENCY_METRICS_SUCCESS';
export const FETCH_COMPETENCY_METRICS_ERROR = 'FETCH_COMPETENCY_METRICS_ERROR';

export const VALIDATION_API_URL =
  window.location.hostname === 'localhost'
    ? process.env.REACT_APP_VALIDATION_SERVICE_ENDPOINT
    : `https://api.${window.location.hostname}`;

export const fetchCompetencyMetrics = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_COMPETENCY_METRICS, isLoading: true });

    try {
      const config = {
        headers: {
          Authorization: getIdTokenFromStorage(),
        },
      };

      const metricsUrlResponse = await axios.get(
        `${VALIDATION_API_URL}/competency`,
        config
      );

      if (metricsUrlResponse.status === 201) {
        const processedDate = metricsUrlResponse.data.processedDate;
        const presignedUrl = metricsUrlResponse.data.url;

        const metricsResponse = await axios.get(presignedUrl);
        if (metricsResponse.status === 200) {
          const metrics = metricsResponse.data.metrics;
          const segmentStackRank = metricsResponse.data.segmentStackRank;
          const pendingDocumentationMetrics =
            metricsResponse.data.pendingDocumentationMetrics;

          const payload = {
            processedDate,
            metrics,
            segmentStackRank,
            pendingDocumentationMetrics,
          };
          dispatch(fetchCompetencyMetricsSuccess(payload));
        } else {
          console.log('there was an error retrieving metrics');
        }
      } else {
        console.log('there was an error retrieving metrics');
      }
    } catch (err) {
      dispatch(fetchCompetencyMetricsError(err));
      console.log(err.message);
    }
  };
};

//TODO: Finish
export const fetchCompetencyMetricsSuccess = (payload) => {
  return { type: FETCH_COMPETENCY_METRICS_SUCCESS, isLoading: false, payload };
};

//TODO: Finish
export const fetchCompetencyMetricsError = () => {
  return { type: FETCH_COMPETENCY_METRICS_ERROR, isLoading: false };
};
