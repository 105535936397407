import { decode } from 'jsonwebtoken';
import qs from 'querystring';
import axios from 'axios';

import { checkExpired } from 'util/core';

export const isProd = () => window.location.hostname === 'crucible.apn.a2z.com';

const ID_TOKEN_KEY = 'id_token';
const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const TOKEN_EXPIRATION_KEY = 'tokenExpiration';

export const COGNITO_ENDPOINT = isProd()
  ? `${process.env.REACT_APP_COGNITO_ENDPOINT_PROD}`
  : `${process.env.REACT_APP_COGNITO_ENDPOINT_DEV}`;

export const COGNITO_OAUTH_ENDPOINT = `${COGNITO_ENDPOINT}/oauth2`;

export const CLIENT_ID = isProd()
  ? `${process.env.REACT_APP_CLIENT_ID_PROD}`
  : `${process.env.REACT_APP_CLIENT_ID_DEV}`;

export const REDIRECT_URI = `${document.location.origin}/auth`;
export const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;
export const COGNITO_AUTH_URL = `${COGNITO_OAUTH_ENDPOINT}/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=openid&identity_provider=${IDENTITY_PROVIDER}`;

export const checkTokenExpiration = async () => {
  if (navigator.onLine && checkAuthTokenExists()) {
    if (tokenExpired()) {
      try {
        await refreshToken();
      } catch (err) {
        logout();
      }
    }
  } else {
    console.error('No authentication found, redirecting user...');

    logout();
    window.location.replace(COGNITO_AUTH_URL);
  }
};

export const refreshToken = async () => {
  const storageRefreshToken = getRefreshTokenFromStorage();
  const authReqBody = {
    grant_type: 'refresh_token',
    client_id: CLIENT_ID,
    refresh_token: storageRefreshToken,
  };

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const response = await axios.post(
    `${COGNITO_OAUTH_ENDPOINT}/token`,
    qs.stringify(authReqBody),
    config
  );

  if (response.status === 200) {
    const authPayload = response.data;
    const expires_in = authPayload.expires_in
      ? parseInt(authPayload.expires_in) / 60
      : 60;
    let expireDate = new Date();
    expireDate.setTime(
      expireDate.setMinutes(expireDate.getMinutes() + expires_in)
    );

    const id_token = authPayload.id_token;
    const access_token = authPayload.access_token;
    const refresh_token = storageRefreshToken;

    // Set the tokens and expiration in local storage.
    login(id_token, access_token, refresh_token, expireDate.toUTCString());
  } else {
    logout();
  }
};

// TODO: Need to fix this and implement this at start.
export const getUsername = () => {
  let username = '';

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(getIdTokenFromStorage(), { json: true });

    const {
      identities: [identity],
    } = decodedIdToken;

    const { userId } = identity;

    username = userId;
  }
  return username;
};

export const getRoles = () => {
  let roles = [];

  if (checkAuthTokenExists()) {
    const decodedIdToken = decode(getIdTokenFromStorage(), { json: true });

    const { profile } = decodedIdToken;

    roles = JSON.parse(profile);
  }
  return roles;
};

export const checkAuthTokenExists = () => {
  const accessTokenExists = getAccessTokenFromStorage() ? true : false;
  const idTokenExists = getIdTokenFromStorage() ? true : false;
  const refreshTokenExists = getRefreshTokenFromStorage() ? true : false;
  return accessTokenExists && idTokenExists && refreshTokenExists;
};

export const tokenExpired = () => {
  const tokenExpiration = getTokenExpirationFromStorage();
  const expirationTime = new Date(tokenExpiration);
  return checkExpired(expirationTime);
};

/**
 * This method will set the tokens we received from the authorization code grant flow.
 *
 * @param {String} id_token Identity token of a user. We will use this to map App Authorization.
 * @param {String} access_token Access token of a user. This will be used when
 * @param {String} refresh_token Refresh token. Retrieve new tokens with a POST requet to 'oauth2/token' with refresh_token, client_id, and grant_type = 'refresh_token'.
 * @param {String} tokenExpiration Expiration time of all tokens.
 */
export const login = (
  id_token,
  access_token,
  refresh_token,
  tokenExpiration
) => {
  setIdTokenToStorage(id_token);
  setAccessTokenToStorage(access_token);
  setRefreshTokenToStorage(refresh_token);
  setTokenExpirationToStorage(tokenExpiration);
};

export const setIdTokenToStorage = (id_token) => {
  localStorage.setItem(ID_TOKEN_KEY, id_token);
};

export const setAccessTokenToStorage = (access_token) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, access_token);
};

export const setRefreshTokenToStorage = (refresh_token) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, refresh_token);
};

export const setTokenExpirationToStorage = (expiration) => {
  localStorage.setItem(TOKEN_EXPIRATION_KEY, expiration);
};

export const getIdToken = async () => {
  if (tokenExpired() && checkAuthTokenExists()) {
    await refreshToken();
  }
  return getIdTokenFromStorage();
};

/**
 * Retrieve id token from storage.
 */
export const getIdTokenFromStorage = () => {
  return localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * Retrieve access token from storage.
 */
export const getAccessTokenFromStorage = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

/**
 * Retrieve refresh token from storage.
 */
export const getRefreshTokenFromStorage = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

/**
 * Retrieve token expiration from storage.
 */
export const getTokenExpirationFromStorage = () => {
  return localStorage.getItem(TOKEN_EXPIRATION_KEY);
};

/**
 * This method will remove all tokens from storage.
 */
export const logout = () => {
  removeIdTokenFromStorage();
  removeAccessTokenFromStorage();
  removeRefreshTokenFromStorage();
  removeTokenExpirationFromStorage();
  window.location.replace(document.location.origin);
};

export const removeIdTokenFromStorage = () => {
  localStorage.removeItem(ID_TOKEN_KEY);
};

export const removeAccessTokenFromStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const removeRefreshTokenFromStorage = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const removeTokenExpirationFromStorage = () => {
  localStorage.removeItem(TOKEN_EXPIRATION_KEY);
};
