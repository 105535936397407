import API from 'util/services/API';
import { ValidationModel } from 'util/models/validation';

// ============================= Constants =============================

export const VALIDATION_LOADING = 'VALIDATION_LOADING';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';

export const FETCH_VALIDATION = 'FETCH_VALIDATION';

export const FETCH_VALIDATIONS_SUCCESS = 'FETCH_VALIDATIONS_SUCCESS';
export const FETCH_VALIDATIONS_ERROR = 'FETCH_VALIDATIONS_ERROR';

export const CREATE_VALIDATION = 'CREATE_VALIDATION';
export const CREATE_VALIDATION_SUCCESS = 'CREATE_VALIDATION_SUCCESS';
export const CREATE_VALIDATION_ERROR = 'CREATE_VALIDATION_ERROR';

export const CONDUCT_VALIDATION = 'CONDUCT_VALIDATION';
export const CONDUCT_VALIDATION_SUCCESS = 'CONDUCT_VALIDATION_SUCCESS';
export const CONDUCT_VALIDATION_ERROR = 'CONDUCT_VALIDATION_ERROR';
// ============================= Actions =============================

/**
 * Set loading flag to true for validations.
 */
export const validationLoading = (loading) => {
  return { type: VALIDATION_LOADING, isLoading: loading };
};

/**
 * Creating a new validation
 */
export const createValidation = (validation, history) => {
  return (dispatch) => {
    dispatch(validationLoading(true));
    delete validation['error'];
    dispatch(createValidationSuccess(validation));

    history.push(`/validations/${validation.validationReferenceId}`);
  };
};

//TODO: Finish
export const createValidationSuccess = (validation) => {
  const newValidation = {
    id: validation.validationReferenceId,
    details: ValidationModel(validation),
  };
  return {
    type: CREATE_VALIDATION_SUCCESS,
    isLoading: false,
    payload: newValidation,
  };
};

//TODO: Finish
export const createValidationError = (payload) => {
  return { type: CREATE_VALIDATION_ERROR, isLoading: false, payload };
};

export const conductValidation = (
  conductedValidation,
  validationResponse,
  history
) => {
  return async (dispatch) => {
    dispatch(validationLoading(true));

    try {
      let data = {};
      const { timeOnReview, ...validations } = validationResponse;
      if (conductedValidation.hasMultipleCaseStudies) {
        data = {
          ...conductedValidation,
          timeOnReview,
          validations: validations,
        };
      } else {
        const { validation, notes } = validationResponse;
        data = {
          ...conductedValidation,
          validation,
          notes,
          timeOnReview,
        };
      }

      await API.createValidation(data);

      dispatch(conductValidationSuccess(data));
      history.push(`/validations/${data.validationReferenceId}/report`);
    } catch (err) {
      console.error(err);
      dispatch(conductValidationError());
      dispatch(console.log('An error has occurred'));
      dispatch(validationLoading(false));
    }
  };
};

//TODO: Finish
export const conductValidationSuccess = (validation) => {
  const newValidation = {
    id: validation.validationReferenceId,
    details: ValidationModel(validation),
  };
  return {
    type: CONDUCT_VALIDATION_SUCCESS,
    isLoading: false,
    payload: newValidation,
  };
};

//TODO: Finish
export const conductValidationError = () => {
  return { type: CONDUCT_VALIDATION_ERROR, isLoading: false };
};

/**
 * Fetch validations
 */
export const fetchValidation = (validationId, history) => {
  return async (dispatch) => {
    dispatch(validationLoading(true));

    try {
      const response = await API.getValidation(validationId);
      if (response.data) {
        const validation = response.data;
        dispatch(createValidationSuccess(validation));
      } else {
        throw new Error('Validation not found.');
      }
    } catch (err) {
      console.error(err);
      dispatch(createValidationError());
      console.log('Thsi validation does not exist');
      history.push(`/validations/new/`);
    }
  };
};

export const fetchValidationsSuccess = (response) => {
  const validations = {};
  response.map((validation) => {
    [validation.validationReferenceId] = ValidationModel(validation);
    return validation;
  });

  return {
    type: FETCH_VALIDATIONS_SUCCESS,
    isLoading: false,
    payload: validations,
  };
};
