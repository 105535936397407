import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Form } from '@amzn/awsui-components-react/';

import WizardFooter from 'views/components/common/Wizard/Footer';
import ChecklistRequirements from 'views/components/Validation/ChecklistRequirements';

const TechnicalValidationRequirements = (props) => {
  const {
    activeStep,
    handleChange,
    handleSubmit,
    stepId,
    category,
    nextStep,
    numberOfSteps,
    previousStep,
    validationControls,
    validationResponse,
    isLoading,
    isValidationOfMultipleCaseStudies,
    validationType,
    status,
    hasApplicationRequirements,
  } = props;

  function filterControl(control) {
    if (hasApplicationRequirements) {
      return control.Scope === 'Application';
    } else {
      return control.Scope !== 'Application';
    }
  }
  const [filteredValidationControls] = validationControls.filter(
    (validation) => {
      return validation.PK === category;
    }
  );
  let controlCategories = [];

  filteredValidationControls.ControlCategories.filter((controlCategory) => {
    controlCategory.Controls.filter(filterControl).map((control) => {
      let controlCategoryItem = controlCategories.find(
        (item) => item.Category === controlCategory.Category
      );
      if (controlCategoryItem) {
        controlCategoryItem.Controls.push(control);
      } else {
        controlCategories.push({
          Category: controlCategory.Category,
          Description: controlCategory.Description,
          Controls: [control],
        });
      }
      return control;
    });
    return controlCategory;
  });

  const title = `${validationType}`;

  return (
    <Form
      header={
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div>{title}</div>
        </div>
      }
      actions={
        <WizardFooter
          activeStep={activeStep}
          nextStep={nextStep}
          numberOfSteps={numberOfSteps}
          previousStep={previousStep}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          status={status}
        />
      }
    >
      <ChecklistRequirements
        controlCategories={controlCategories}
        handleChange={handleChange}
        validationResponse={validationResponse}
        stepId={stepId}
        isValidationOfMultipleCaseStudies={isValidationOfMultipleCaseStudies}
        status={status}
        hasApplicationRequirements={hasApplicationRequirements}
      />
    </Form>
  );
};

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.validations.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  //TODO: Remove/change this
  return {
    dispatch,
  };
};

const connectDecoratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TechnicalValidationRequirements);

export default withRouter(connectDecoratedComponent);
