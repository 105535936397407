import { timer } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  checkAuthTokenExists,
  checkTokenExpiration,
} from 'util/services/AuthService';

const checkAuthTokenExpirationEpic = (action$, state$) =>
  timer(0, 5000).pipe(
    map((action) => {
      if (document.location.pathname !== '/auth' && checkAuthTokenExists()) {
        checkTokenExpiration();
      }
      return { type: '' };
    })
  );

export default checkAuthTokenExpirationEpic;
