import React from 'react';
import { Tabs } from '@amzn/awsui-components-react';
import ValidationComponent from './ValidationComponent';

class SummaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeTabId: Object.keys(props.validations)[0] };
    this.activeStepComponent = null;
  }

  chooseValidation = (tabId) => {
    this.setState({ ...this.state, activeTabId: tabId });
  };

  render() {
    const {
      validations,
      validationControls,
      hasMultipleCaseStudies,
    } = this.props;

    const currentValidation = validations[this.state.activeTabId];

    let validationComponent = null;

    if (hasMultipleCaseStudies) {
      let tabs = [];

      Object.keys(validations).forEach((key) => {
        const { customerName, hasApplicationRequirements } = validations[key];
        tabs.push({
          label: hasApplicationRequirements
            ? 'Application Requirements'
            : `Customer Reference: ${customerName}`,
          id: `${key}`,
          content: <ValidationComponent validation={currentValidation} />,
        });
      });
      validationComponent = (
        <Tabs
          tabs={tabs}
          activeTabId={this.state.activeTabId}
          ariaLabel="Resource details"
          onChange={({ detail }) =>
            this.setState({ activeTabId: detail.activeTabId })
          }
        />
      );
    } else {
      validationComponent = (
        <ValidationComponent
          validation={currentValidation}
          validationControls={validationControls}
        />
      );
    }

    return (
      <div className="custom-layout awsui-util-pt-s">
        <div className="custom-col-content">{validationComponent}</div>
      </div>
    );
  }
}

export default SummaryComponent;
