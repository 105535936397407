import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { routeFactory, RouteType } from 'config/roles';
import { UserContext } from 'context/UserContext';

import './style.css';
import { SideNavigation } from '@amzn/awsui-components-react';

const NavigationDrawer = ({ history, match }) => {
  const { user } = useContext(UserContext);

  const getPath = () => {
    let activePath = match.path;
    const path = match.path.split('/');
    if (path.length > 3) {
      activePath = `/${path[1]}/${path[2]}`;
    }
    return activePath;
  };

  const userRoles = user.roles;
  const navLinks = [];
  const addedRoutes = [];

  let routes = routeFactory(userRoles);
  const parentRoutes = routes.filter(
    (route) => route.type === RouteType.PARENT
  );

  for (let parentRoute of parentRoutes) {
    if (!addedRoutes.includes(parentRoute.path)) {
      const childRoutes = routes.filter((route) => {
        return (
          route.type === RouteType.CHILD &&
          route.parent &&
          route.parent === parentRoute.name
        );
      });

      if (childRoutes.length > 0) {
        const childNavLinks = [];
        for (let childRoute of childRoutes) {
          if (!addedRoutes.includes(childRoute.path)) {
            const newNavLink = {
              type: 'link',
              text: childRoute.name,
              href: childRoute.path,
            };
            childNavLinks.push(newNavLink);
            addedRoutes.push(childRoute.path);
          }
        }

        navLinks.push({
          type: 'expandable-link-group',
          text: parentRoute.name,
          href: parentRoute.path,
          items: childNavLinks,
        });
      } else {
        navLinks.push({
          type: 'link',
          text: parentRoute.name,
          href: parentRoute.path,
        });
      }
      addedRoutes.push(parentRoute.path);
    }
  }

  const staticLinks = [
    { type: 'divider' },
    {
      type: 'link',
      text: 'Forge Roadmap',
      href:
        'https://issues.amazon.com/issues/search?q=is%3A(Open)+in%3A(c537c8c3-e880-4937-b73f-8fdc5832df77)&sort=score+desc',
      external: true,
    },
    {
      type: 'link',
      text: 'Request a feature',
      href: 'https://tiny.amazon.com/tqrtfh1p',
      external: true,
    },
    {
      type: 'link',
      text: 'Report a bug',
      href:
        'https://t.corp.amazon.com/create/templates/ae0b253d-2e15-4c3b-8c5b-3df4e42f15e4',
      external: true,
    },
    {
      type: 'link',
      text: 'Report a checklist defect',
      href:
        'https://sim.amazon.com/issues/create?template=7fd9b1f5-eadd-435b-bce3-669283c7e120',
      external: true,
    },
  ];

  const finalNavItems = [...navLinks, ...staticLinks];

  let header = {
    href: '/',
    text: 'Crucible',
  };

  return (
    <SideNavigation
      header={header}
      items={finalNavItems}
      activeHref={getPath()}
      onFollow={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!e.detail.external) {
          history.push(e.detail.href);
        } else {
          window.open(e.detail.href, '_blank');
        }
      }}
    />
  );
};

export default withRouter(NavigationDrawer);
