// Form Names
export const COMPETENCY_VALIDATION = 'COMPETENCY_VALIDATION';
export const BASELINE_VALIDATION = 'BASELINE_VALIDATION';

// Notification Types
export const INFO = 'info';
export const SUCCESS = 'success';
export const WARNING = 'warning';
export const ERROR = 'error';

// Notification Status
export const ACTIVE = 'active';
export const DISMISSED = 'dismissed';

// Technical Validation Field Ids
export const PARTNER_NAME = 'partner';
export const TECHNICAL_VALIDATION_ID = 'validationReferenceId';
export const WORKLOAD_NAME = 'workload';

export const IGNORED_BY_STATUS =
  'Note: This requirement is ignored for status calculation.';
export const IGNORED_BY_STATUS_BADGE = 'Ignored by Status';

export const TIME_ON_REVIEW_ATTRIBUTE = 'timeOnReview';
