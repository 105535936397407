import React from 'react';
import { FormField, Textarea } from '@amzn/awsui-components-react';

const ControlledTextAreaField = ({
  label,
  name,
  error,
  onChange,
  description,
  placeholder,
  value,
  onInput,
  autofocus = false,
  stretch = true,
  rows = 3,
  disabled = false,
}) => {
  return (
    <FormField
      className="awsui-util-d-b awsui-util-mb-m"
      description={description}
      stretch={stretch}
      errorText={error}
      label={label}
    >
      <Textarea
        onChange={onChange}
        onInput={onInput}
        placeholder={placeholder}
        rows={rows}
        autofocus={autofocus}
        name={name}
        value={value}
        disabled={disabled}
      />
    </FormField>
  );
};

export default ControlledTextAreaField;
