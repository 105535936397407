import React, { lazy, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { routeFactory } from 'config/roles';

import ConsoleWrapper from 'views/components/common/Console/Wrapper/ConsoleWrapper';
// import NotFound from 'views/components/common/NotFound';
// const NotFound = lazy(() => import('views/components/common/NotFound'));
const Auth = lazy(() => import('views/components/common/Auth'));
const Routes = () => {
  const { user } = useContext(UserContext);
  const userRoles = user.roles;
  const routes = routeFactory(userRoles);
  let rootRedirect = '';

  if (routes.length > 1) {
    rootRedirect = routes.filter((route) => route.main)[0].path;
  }

  // for (let userRole of userRoles) {
  //   if (roles[userRole]) {
  //     const roleRoutes = roles[userRole].routes;
  //     for (let roleRoute of roleRoutes) {
  //       if (!addedRoutes.includes(roleRoute.path)) {
  //         if (rootRedirect === '' && roleRoute.main) {
  //           rootRedirect = roleRoute.path;
  //         }
  //         routes.push(roleRoute);
  //         addedRoutes.push(roleRoute.path);
  //       }
  //     }
  //   }
  // }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/auth" render={(props) => <Auth {...props} />} />

        {routes.map((route, index) => {
          const Component = route.component;
          return (
            <Route
              exact
              path={route.path}
              key={index}
              render={(props) => (
                <ConsoleWrapper component={<Component {...props} />} />
              )}
            />
          );
        })}
        <Route exact path="/" render={() => <Redirect to={rootRedirect} />} />
        <Route path="*" component={() => <Redirect to={rootRedirect} />} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
