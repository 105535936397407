import { ValidationsMap } from 'config/validations';
import { codeToLanguageMap } from './constants/language-constants';

/**
 * Redirect a user to the homepage.
 *
 * @param {*} history
 * @param {*} planId
 */
export const redirectHome = (history) => {
  history.push(`/validations`);
};

/**
 * Returns a list of yeears 5 years before and 5 years after the provided year.
 *
 * @param {number} year
 */
export const getTenYearRange = (year) => {
  const selectOptions = [];
  for (let i = -5; i <= 5; i++) {
    const calculatedYear = year + i;
    const selectOption = {
      label: calculatedYear.toString(),
      id: calculatedYear.toString(),
    };
    selectOptions.push(selectOption);
  }
  return selectOptions;
};

export const getTodayYYYYMMDDFormattedDate = () => {
  return getYYYYMMDDFormattedDate(new Date());
};

/**
 * Returns a string of the date in a YYYY-MM-DD format.
 *
 * @param {Date} date
 */
export const getYYYYMMDDFormattedDate = (date) => {
  if (checkDateExist(date)) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${formatToTwoDigit(month)}-${formatToTwoDigit(day)}`;
  } else {
    return '-';
  }
};

/**
 * Check whether we are provided an actual date to do date comparisons.
 * @param {*} firstDate
 */
const checkDateExist = (firstDate) => {
  const firstDateExists =
    !isNaN(firstDate) && firstDate && firstDate instanceof Date;
  return firstDateExists;
};

/**
 * Returns a string of a number as two digits.
 *
 * @param {Number} number
 */
export const formatToTwoDigit = (number) => {
  return number < 10 ? '0' + number : number;
};

/**
 * Capitalize a word.
 *
 * @param {String} word
 */
export const capitalize = (word) => {
  let lowercasePhrase = word.toLowerCase();
  const splitPhrase = lowercasePhrase.split(' ');
  const capitalizedPhrase = splitPhrase.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedPhrase.join(' ');
};

/**
 * Checks whether a the first object is different from the second object.
 * @param {Object} value1
 * @param {Object} value2
 */
export const checkDirty = (value1, value2) =>
  JSON.stringify(value1) !== JSON.stringify(value2);

/**
 * Sort object keys - This is only sorts the first level of object keys.
 * @param {Object} object
 */
export const sortObjectKeysShallow = (object) => {
  const ordered = {};
  if (object) {
    Object.keys(object)
      .sort()
      .forEach(function(key) {
        ordered[key] = object[key];
      });
  }
  return ordered;
};

/**
 *
 * @param {Date} expirationDate
 * @param {Date} dateToCheck
 */
export const checkExpired = (expirationDate, dateToCheck = new Date()) => {
  return dateToCheck > expirationDate;
};

/**
 * Expects a list of strings to return a list of maps to be used for the select component.
 * @param {*} items
 */
export const createSelectionMap = (items, map) =>
  items.map((item) => {
    return { id: item, label: map ? map[item] : item };
  });

/**
 * TODO: Document this
 * @param {*} controls
 */
export const getValidationControlMapping = (controls) => {
  const controlMapping = {};
  const controlKey = [];
  controls.map((control) => {
    const PK = control.PK;
    if (PK && !controlKey.includes(PK)) {
      let category = [];
      const { Language, PartnerType, SegmentType } = control;

      if (PartnerType && PartnerType !== 'Common') {
        category.push(PartnerType);
      }
      if (SegmentType && SegmentType !== 'Common') {
        category.push(SegmentType);
      }
      if (Language) {
        category.push(`(${Language})`);
      } else {
        category.push('(EN)');
      }
      controlMapping[PK] = category.join(' ');

      controlKey.push(PK);
    }
    return control;
  });
  return controlMapping;
};

export const createLanguageList = (definitions) => {
  const allLanguages = definitions.map((definition) => {
    return definition.Language;
  });
  const uniqueLanguages = createUniqueList(allLanguages);
  return createSelectionMap(uniqueLanguages, codeToLanguageMap);
};

export const createValidationTypesList = (definitions, language) => {
  const allValidationTypes = definitions
    .filter((definition) => definition.Language === language.id)
    .map((definition) => {
      return definition.ValidationType;
    });
  const uniqueDefinitions = createUniqueList(allValidationTypes);
  // Return validation types in sorted order
  return createSelectionMap(uniqueDefinitions.sort());
};

export const createCategoryTypesList = (
  definitions,
  validationType,
  language
) => {
  const listOfCategories = [];
  definitions
    .filter(
      (definition) =>
        definition.ValidationType === validationType &&
        definition.Language === language
    )
    .forEach((definition) => {
      const validationLabel = getNameModifier(definition.ValidationType)(
        definition
      );
      const category = {
        id: definition.PK,
        label: `${validationLabel} (${definition.Version})`,
      };
      listOfCategories.push(category);
    });
  return listOfCategories;
};

export const getValidationType = (validationType) => {
  let validation = ValidationsMap['Default'];
  if (validationType in ValidationsMap) {
    validation = ValidationsMap[validationType];
  }
  return validation;
};

export const getNameModifier = (validationType) => {
  return getValidationType(validationType).nameModifier;
};

export const createUniqueList = (list) => {
  return [...new Set(list)];
};

/**
 * Returns `true` if the validation has a remediated status or a partially remediated status.
 * The validation response has different fields depending on which API endpoint was used to fetch it,
 * thus the three ways below to determine partial remediation.
 *
 * @param {*} validation
 * @return {*}
 */
export const isRemediated = (validation) => {
  if (!validation) {
    return false;
  }

  // full remediation
  if (validation && validation.status === 'Remediated') {
    return true;
  }

  // partial remediation, if called from get-all-validation
  if (validation && validation.percentControlsFailed) {
    return (
      validation &&
      validation.status === 'Failed' &&
      validation.percentControlsFailed > 0
    );
  }

  // partial remediation, if called from get-one-validation and multi-case
  if (
    validation.validations &&
    Object.keys(validation.validations).length > 0
  ) {
    return Object.values(validation.validations).find((element) =>
      Object.keys(element).includes('remediations')
    );
  }

  // partial remediation, if called from get-one-validation and not multi-case
  if (validation && validation.status === 'Failed') {
    return Object.keys(validation).includes('remediations');
  }

  return false;
};
