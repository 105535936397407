import React from 'react';

export const COLUMN_DEFINITIONS = [
  {
    id: 'eventType',
    header: 'Event Type',
    cell: (item) => item.event_type,
    minWidth: '75px',
  },
  {
    id: 'date',
    header: 'Date',
    cell: (item) => new Date(item.timestamp).toLocaleString(),
    minWidth: '75px',
  },
  {
    id: 'userId',
    header: 'User',
    cell: (item) => (
      <a href={`https://phonetool.amazon.com/users/${item.user_id}`}>
        {item.user_id}
      </a>
    ),
    minWidth: '75px',
  },
  {
    id: 'payload',
    header: 'Payload',
    cell: (item) => item.payload,
    minWidth: '200px',
  },
];

export const SORTABLE_COLUMNS = [
  {
    id: 'date',
    comparator: (item1, item2) => {
      if (new Date(item1.timestamp) > new Date(item2.timestamp)) return 1;
      if (new Date(item1.timestamp) < new Date(item2.timestamp)) return -1;
      return 0;
    },
  },
  { id: 'userId', field: 'User' },
];
