import React from 'react';
import { FormField, Input } from '@amzn/awsui-components-react/';

const ControlledInputField = ({
  label,
  name,
  error,
  description,
  placeholder,
  type = 'text',
  autofocus = false,
  stretch = true,
  required = false,
  value,
  onChange,
  disabled = false,
  hintText,
}) => {
  return (
    <FormField
      className="awsui-util-d-b awsui-util-mb-m"
      description={description}
      errorText={error}
      stretch={stretch}
      label={label}
      hintText={hintText}
    >
      <Input
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        autofocus={autofocus}
        required={required}
        disabled={disabled}
        value={value}
      />
    </FormField>
  );
};

export default ControlledInputField;
