import React from 'react';
import {
  Checkbox,
  ColumnLayout,
  FormField,
  FormSection,
} from '@amzn/awsui-components-react';

const CheckboxSelect = ({
  error,
  validationType,
  categories,
  handleCheckboxSelect,
  disabled = false,
}) => (
  <FormSection header={`${validationType} Type`}>
    <ColumnLayout columns={2}>
      <div data-awsui-column-layout-root="true">
        <FormField errorText={error && error['checkboxSelect']}>
          {categories
            .sort((a, b) => a.id > b.id)
            .map((category, index) => {
              return (
                <Checkbox
                  key={index}
                  label={category.label}
                  name={category.id}
                  onChange={(e) => {
                    handleCheckboxSelect(e, category.id);
                  }}
                  disabled={disabled}
                />
              );
            })}
        </FormField>
      </div>
    </ColumnLayout>
  </FormSection>
);

export default CheckboxSelect;
